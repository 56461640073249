import React from "react";
import logo from "../../assets/logo_slip.png";
import legal from "../../assets/Legals.png";
import { Image } from "react-bootstrap";
import QRCodeComponent from "./QRCode";

const PrintSlipComponent = React.forwardRef((props, ref) => {
  const { order } = props || {};

  return (
    <div
      ref={ref}
      style={{
        padding: "40px 20px",
        width: "8in", // Fixed width for 8.5 x 11 inch paper
        height: "10in", // Fixed height with room for margins
        margin: "0 auto",
        fontFamily: "serif",
        boxSizing: "border-box",
        pageBreakInside: "avoid", // Prevent breaking within the container
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          textAlign: "left",
          pageBreakInside: "avoid", // Ensure this block doesn't split
        }}
      >
        <Image
          src={order?.vendor?.logo || logo}
          alt="logo"
          style={{ height: "120px", width: "120px" }}
        />
        <div>
          <div style={{ display: "flex" }}>
            <div style={{ width: "50px" }}>
              <p style={{ fontSize: "14px", fontWeight: "bold" }}>From:</p>
            </div>
            <div style={{ flexGrow: 1 }}>
              <p style={{ fontSize: "12px", marginBottom: 0 }}>
                {order?.vendor?.company_name || "Valley Hatchery"}
              </p>
              <p style={{ fontSize: "12px", marginBottom: 0 }}>
                {order?.vendor?.street_address || "8289 State Street #143"}
              </p>
              <p style={{ fontSize: "12px", marginBottom: 0 }}>
                {order?.vendor?.city || "Kinsman"},{" "}
                {order?.vendor?.state || "Ohio"}{" "}
                {order?.vendor?.zip_code || "44428"}
              </p>
              <p style={{ fontSize: "12px", marginBottom: 0 }}>
                {order?.vendor?.phone_number || "1-800-652-7606"}
              </p>
            </div>
          </div>

          <div style={{ display: "flex", fontWeight: "bold", marginTop: "25px" }}>
            <div style={{ width: "50px" }}>
              <p style={{ fontSize: "15px", fontWeight: "bold" }}>To:</p>
            </div>
            <div style={{ flexGrow: 1 }}>
              <p style={{ fontSize: "13px", marginBottom: 0 }}>
                {order?.customer_name}
              </p>
              <p style={{ fontSize: "13px", marginBottom: 0 }}>
                {order?.shipping_address}
              </p>
              <p style={{ fontSize: "13px", marginBottom: 0 }}>
                {order?.city}, {order?.state} {order?.zip_code}
              </p>
              <div style={{ display: "flex", alignItems: "flex-start" }}>
                <div style={{ width: "100px" }}>
                  <p style={{ fontSize: "13px", marginBottom: 0 }}>
                    Call on arrival:
                  </p>
                </div>
                <div style={{ flexGrow: 1 }}>
                  <p style={{ fontSize: "13px", marginBottom: 0 }}>
                    {order?.phone_number}
                  </p>
                  {order?.alt_phone_number && (
                    <p style={{ fontSize: "13px", marginBottom: 0 }}>
                      {order?.alt_phone_number}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <QRCodeComponent value={order?.order_number} size={100} level="H" />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "left",
          marginBottom: "20px",
          marginLeft: "20px",
          marginTop: "15px",
        }}
      >
        <div style={{ fontSize: "13px", fontWeight: "bold" }}>
          <p style={{ marginBottom: 0 }}>Order: {order?.order_number}</p>
          <p style={{ marginBottom: 0 }}>
            Shipped: {order?.available_ship_date?.date}
          </p>
          <p style={{ marginBottom: 0 }}>Status: {order?.order_type}</p>
        </div>
      </div>

      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          pageBreakInside: "avoid",
        }}
      >
        <thead>
          <tr style={{ background: "#AFAFAF", color: "black" }}>
            <th style={{ border: "1px solid #ccc", padding: "8px" }}>Qty</th>
            <th style={{ border: "1px solid #ccc", padding: "8px" }}>Item</th>
            <th style={{ border: "1px solid #ccc", padding: "8px" }}>Note</th>
          </tr>
        </thead>
        <tbody style={{ fontSize: "12px" }}>
          {order?.order_list?.map((item, index) => (
            <tr key={index}>
              <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                {item?.quantity}
              </td>
              <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                {item?.items?.product_name}
              </td>
              <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                {item.note}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div
        style={{
          margin: "30px 0px",
          display: "flex",
          justifyContent: "space-between",
          pageBreakInside: "avoid",
        }}
      >
        <Image
          src={legal}
          alt="legal"
          style={{ width: "150px", height: "50px" }}
        />
        <p
          style={{
            width: "380px",
            border: "1px solid gray",
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          Upon receipt of your started pullets, allow them a day to acclimate in
          their new coop before free-ranging. It may take some time for them to
          get used to their new environment. Provide free-choice layer feed and
          water, and consider adding electrolytes to the water to help with
          recovery from transit. Do not immediately add them to your existing
          flock. Instead, separate the new birds so they can see each other, and
          after a day of acclimating, add them to the roost at night when your
          existing flock is roosting. Monitor the integration closely. Feel free
          to call us with any questions!
        </p>
      </div>

      {/* Print-Specific CSS */}
      <style>
        {`
          @media print {
            body {
              margin: 0;
              padding: 0;
            }
            div[ref] {
              width: 100%;
              height: 100%;
              margin: 0;
              page-break-before: always;
            }
          }
        `}
      </style>
    </div>
  );
});

export default PrintSlipComponent;
