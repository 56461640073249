import React, { useEffect, useState, useRef } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactToPrint from "react-to-print";
import fetchShipmentDateList from "../../redux/thunk/fetchShipmentDateList";
import PrintSlipComponent from "./PrintSlipComponent";
import fetchOrderReport from "../../redux/thunk/fetchOrderReport";
import Loading from "../ui/Loading";
import Error from "../ui/Error";
import fetchShipmentAvaibility from "../../redux/thunk/fetchShipmentAvaibility";
import fetchShipmentCoverReport from "../../redux/thunk/fetchShipmentCoverReport";
import CoverReport from "./CoverReport";
import fetchOrderSummary from "../../redux/thunk/fetchOrderSummary";
import OrderSummary from "./OrderSummary";

export default function Slip() {
  const [searchDate, setSearchDate] = useState("");
  const [slipOpen, setSlipOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Selectors for state
  const { userInfo } = useSelector((state) => state.login) || {};
  const { loading, error, isError, shipments } =
    useSelector((state) => state.shipmentDateList) || {};
  const {
    loading: shipmentLoading,
    error: shipmentError,
    isError: shipmentIsError,
    shipment,
  } = useSelector((state) => state.shipmentCoverReport) || {};
  const {
    loading: reportLoading,
    error: reportError,
    isError: reportIsError,
    orders,
  } = useSelector((state) => state.orderReport) || {};
  const {
    loading: summaryLoading,
    error: summaryError,
    isError: summaryIsError,
    summary,
  } = useSelector((state) => state.orderSummary) || {};

  const printRef = useRef(null);

  // Format date utility
  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-").map(Number);
    const date = new Date(year, month - 1, day);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "2-digit",
    });
  };

  // Submit handlers
  const submitHandler = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const handleYes = () => {
    setShowModal(false);
    dispatch(fetchShipmentAvaibility(searchDate));
    dispatch(fetchOrderReport(searchDate));
    dispatch(fetchShipmentCoverReport(searchDate));
    dispatch(fetchOrderSummary(searchDate));
  };

  const handleNo = () => {
    setShowModal(false);
    dispatch(fetchOrderReport(searchDate));
    dispatch(fetchShipmentCoverReport(searchDate));
    dispatch(fetchOrderSummary(searchDate));
  };

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    }
    dispatch(fetchShipmentDateList);
  }, [navigate, userInfo, dispatch]);

  useEffect(() => {
    setSlipOpen(orders && orders.length > 0);
  }, [orders]);

  const handleCustomPrint = () => {
    const printContent = printRef.current.innerHTML;
    const originalContent = document.body.innerHTML;

    // Replace the body content with the specific div's content
    document.body.innerHTML = printContent;
    window.print();

    // Restore the original content after printing
    document.body.innerHTML = originalContent;
    window.location.reload(); // Reload to re-apply React's virtual DOM
  };

  // Render
  return (
    <Container>
      {(loading || reportLoading || shipmentLoading || summaryLoading) && (
        <Loading />
      )}
      {(isError || shipmentIsError || summaryIsError || reportIsError) && (
        <Error
          message={
            isError
              ? error
              : shipmentIsError
              ? shipmentError
              : summaryIsError
              ? summaryError
              : reportError
          }
        />
      )}
      <Row className="justify-content-md-center">
        <Col xs={6} md={6} sm={12} className="text-center my-3">
          <h1>Print Packing Slip</h1>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col xs={6} md={6} sm={12}>
          <Form onSubmit={submitHandler}>
            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="me-3" style={{ flex: "1" }}>
                Select Ship Date
              </Form.Label>
              <Form.Control
                as="select"
                value={searchDate}
                onChange={(e) => setSearchDate(e.target.value)}
                required
                style={{ flex: "3" }}
              >
                <option value="">Please Select Date</option>
                {shipments?.map((i, index) => (
                  <option key={index} value={i?.id}>
                    {formatDate(i?.date)}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Button variant="success" type="submit" className="w-100 mb-3">
              Print Packing Slip
            </Button>
          </Form>
        </Col>
      </Row>
      {slipOpen && (
        <Row className="justify-content-md-center">
          <Col xs={6} md={6} sm={12}>
            <ReactToPrint
              trigger={() => <button className="mb-3">Print Slip</button>}
              content={handleCustomPrint}
            />
            <div ref={printRef} className="print-content">
              <div className="print-container">
                <CoverReport shipment={shipment} />
              </div>
              <div className="print-container">
                {Array.isArray(orders) &&
                  orders.map((order) => (
                    <PrintSlipComponent key={order?.id} order={order} />
                  ))}
              </div>
              <div className="print-container">
                <OrderSummary summary={summary} />
              </div>
            </div>
          </Col>
        </Row>
      )}

      {/* Modal for Confirmation */}
      <Modal show={showModal} onHide={handleNo}>
        <Modal.Header closeButton>
          <Modal.Title>Availability Option</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Would you like to turn off availability for this ship date?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleNo}>
            No
          </Button>
          <Button variant="primary" onClick={handleYes}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
