import React from "react";
import "./SummaryCard.css"; // Import the CSS file

export default function SummaryCard({ vendor, index }) {
  const defaultVendorName = "Valley Hatchery";

  return (
    <div key={index} className="summary-card">
      <p className="vendor-name">
        Vendor: {vendor?.company_name || defaultVendorName}
      </p>
      <div className="table-container">
        <table className="summary-table">
          <thead>
            <tr>
              <th>Order Number</th>
              <th>Customer Name</th>
              <th>Total Pullets</th>
              <th>Breakdown</th>
            </tr>
          </thead>
          <tbody>
            {(vendor?.orders || []).map((order, index) => (
              <tr
                key={index}
                className={index % 2 === 0 ? "even-row" : "odd-row"}
              >
                <td>{order?.order_number || "N/A"}</td>
                <td>{order?.customer_name || "Unknown"}</td>
                <td>{order?.total_pullet || 0}</td>
                <td>
                  {(order?.breakdown || []).map((item, index) => (
                    <span key={index}>
                      {`${item?.count || 0} ${item?.product_name || "Unknown"}`}
                      {index < order.breakdown.length - 1 ? ", " : ""}
                    </span>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <hr />
    </div>
  );
}
